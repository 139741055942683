import { Box } from '@mui/material';
import { isPNDataType } from '@pn/core/domain/data';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { ConfigurationError } from '@pn/core/errors';
import { useLeaseProduction } from '@pn/core/providers/production/useLeaseProduction';
import { useProductionStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import { DenseTable } from 'src/ui/main-panel/components/dense-components/DenseTable';
import { ProductionComponent } from 'src/ui/main-panel/components/Production';

// http://us.localhost:3000/wells_usa/42473312080000/lease_production

type Props = {
  item: WorkspaceItem;
};

export const LeaseProduction = ({ item }: Props) => {
  if (!isPNDataType(item.dataType)) {
    throw new ConfigurationError(
      `Cannot render Lease Production panel for a non-PN data type [${item.dataType}]`
    );
  }

  const { isFetching, productionItems } = useProductionStorage('lease');

  const { dataItemRequested } = useWorkspaceStorage();

  const table = useLeaseProduction(dataItemRequested.id);

  if (isNil(dataItemRequested.id) || isNil(table)) return null;

  return (
    <>
      <ProductionComponent
        dataType={item.dataType}
        isFetching={isFetching}
        productionItems={productionItems}
      />
      <Box mt={2} />
      <DenseTable dataItemId={dataItemRequested.id} table={table} />
    </>
  );
};
