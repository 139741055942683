import { dependencies } from '@pn/core/dependencies';
import { DetailsTable } from '@pn/core/domain/data-info';
import { handleError } from '@pn/core/errors/handleError';
import { productionActions } from '@pn/core/storage';
import { apiProductionProvider } from '@pn/services/api/production/apiProductionProvider';
import { isString } from 'lodash-es';
import React from 'react';

export function useLeaseProduction(id: string | number | undefined) {
  const { isAuthenticated } = dependencies.useAuthenticationService();

  const [detailsTable, setDetailsTable] = React.useState<
    DetailsTable | undefined
  >();

  React.useEffect(() => {
    if (!isAuthenticated || !isString(id)) return;

    (async () => {
      const leaseWellsTable = await getLeaseProduction(id);
      setDetailsTable(leaseWellsTable);
    })();
  }, [id, isAuthenticated]);

  return detailsTable;
}

async function getLeaseProduction(
  id: string
): Promise<DetailsTable | undefined> {
  try {
    productionActions('lease').request();

    const { productionItems, detailsTable } =
      await apiProductionProvider.getLeaseProduction(id);

    productionActions('lease').receive(productionItems);

    return detailsTable;
  } catch (error) {
    handleError({
      error,
      onError: productionActions('lease').error,
      userFriendlyMessage: `Failed to get lease production`,
    });

    return undefined;
  }
}
